import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBtn,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CGeneralMedia,
  CLinkCard02,
  LPickup,
  AssetImage,
  CHeroImg,
  CDefinition,
  CBtnList,
  CAccordionCard,
  LOgata,
  CSliderMedia,
} from '../../../../components/_index';
import menuChoice from '../../../../utils/menu-choice';
import menuGet from '../../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'dinner',
            ja: 'ディナー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/chefsterrace/dinner/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/chefsterrace/dinner/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: 'シェフズテラス',
                path: '/restaurants/chefsterrace/dinner/',
              },
            ],
            current: {
              label: 'ディナー',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect" style={{ backgroundColor: '#EFF0F5' }}>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                en: <>DINNER</>,
              }}
            />
            <p className="c_sectLead u_mb20">
              東北・宮城の豊かな恵みで「素敵な時間」を叶えるシェフズ
              テラスの一皿
            </p>
            <p className="c_sectLead">
              雄大な山々と海に囲まれ、新鮮な空気と水に育まれたこの地で
              <br />
              丹精込めて育てられた食材を、シェフが独創的に仕上げる料理の数々。
              <br />
              四季により表情を変える美しいガーデンとともにご堪能ください。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/chefsterrace/dinner/img_hero.png',
                alt: '',
              }}
            />

            {/* LIMITED MENU - 期間限定メニュー */}
            {/* <LWrap size="small" exClass="u_mtExLarge">
              <CSectTitle
                title={{
                  ja: <>期間限定メニュー</>,
                  en: <>LIMITED MENU</>,
                }}
              />
              <CHeroImg
                img={{
                  src: '/assets/images/restaurants/chefsterrace/img_chefsterrace_limited.png',
                  alt: '',
                }}
                imgSp={{
                  src: '/assets/images/restaurants/chefsterrace/img_chefsterrace_limited__sp.png',
                  alt: '',
                }}
              />
              <h3 className="c_headingLv3 u_tac">
                イルミネーションフレンチディナー
              </h3>
              <p className="c_sectLead">
                ビーツのソースが鮮やかな前菜をはじめ、濃厚な里芋のマッシュポテトとチャウダーで味わう白身魚のポワレなど、
                <br className="u_pc" />
                ライトアップされたガーデンを眺めながら、シェフが腕によりをかけたフレンチコースをお楽しみいただけます。
                <br className="u_pc" />
                国産牛をシンプルにグリルしたメインはフレンチ王道のソースで堪能ください。
              </p>
              <CBtnList
                data={[
                  {
                    label: 'メニューはこちら',
                    link: {
                      href: '/assets/files/pdf/che_limited_menu.pdf',
                      blank: false,
                    },
                    color: 'borderTheme',
                    icon: 'blank',
                  },
                ]}
              /> 
            </LWrap>*/}
            {/* LIMITED MENU - 期間限定メニュー */}

            <CGeneralMedia
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner02.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        ANNIVERSARY
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        大切な人たちとの特別な日のお祝いを、上質な料理とロケーションで彩ります。
                        <br />
                        この日の感動をつないで、忘れられないひとときを。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CGeneralMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner04.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        CUISINE
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        気のおけない仲間と集う日は、バルスタイルでお好みの料理をシェアして、ホテルセレクトのワインを傾ける。
                        <br />
                        シェフおすすめの一皿で会話も弾む、粋なホテルの楽しみ方。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            {/* <CGeneralMedia
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        OPEN KITCHEN
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        調理される素材の音や香りを楽しめるのも魅力のひとつ。
                        <br />
                        五感を研ぎ澄ましながら、出来立てお召し上がりいただく。
                        <br />
                        それがシェフのこだわりです。
                      </p>
                    </>
                  ),
                },
              ]}
            /> */}
            <CGeneralMedia
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner03.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        NIGHT GARDEN
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        シェフズ
                        テラスでは、ガーデンを臨むテラス席でのディナーもおすすめ。
                        <br />
                        アラカルトやコース料理はもちろん、BBQやパーティーメニューを、移りゆく季節とともにおたのしみください。
                      </p>
                      <div className="imgList">
                        <figure className="imgWrapper">
                          <AssetImage
                            src="/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner06.png"
                            alt=""
                          />
                        </figure>
                        <figure className="imgWrapper">
                          <AssetImage
                            src="/assets/images/restaurants/chefsterrace/dinner/img_chefsterrace_dinner07.png"
                            alt=""
                          />
                        </figure>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      {/* <section className="l_sect" style={{ backgroundColor: '#D1D7E3' }}>
        <LWrap>
          <CSectTitle
            title={{
              ja: <>季節のおすすめメニュー</>,
              en: (
                <>
                  SEASON’S <br className="u_sp" />
                  RECOMMEND <br className="u_sp" />
                  MENU
                </>
              ),
            }}
          />
          <CLinkCard02
            align="center"
            data={menuChoice(menuGet(), ['シェフズテラス DINNER'])}
          />
        </LWrap>
      </section> */}
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
